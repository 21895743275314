@font-face {
  font-family: logoFont;
  src: url(fonts/logoFont.ttf);
}

@font-face {
  font-family: normalFont;
  src: url(fonts/normalFont.ttf);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #282c34;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  cursor: pointer;
}

.App {
  text-align: center;
}

.App-button-group button {
  margin: 0 5px;
}

.App-profile-image {
  border-radius: 50%;
  height: 65px;
}

.App-gif:hover {
  cursor: pointer;
}

.App-selected-gif {
  border: 2px solid red;
}
