.App {
  text-align: center;
  font-family: normalFont;
  height: 100%;
  width: 100%;
}

p {
  margin-bottom: 0;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-header .logo {
  color: #00008B;
  font-family: logoFont;
  font-size: 600%;
}

.App-header .logo {
  position: relative;
  margin-bottom: 4%;
}

.App-header .logo .gameSpan {
  color: #EF3A16;
}

.helperText {
  color: grey;
  font-size: 18px;
}

html, body {
  height: 100%;
  width: 100%;
}

#box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 50%;
  height: 100%;
}

.gradient-border {
  padding: 4%;
  --borderWidth: 2vh;
  background: white;
  position: relative;
  border-radius: var(--borderWidth);
}
.gradient-border:after {
  content: '';
  position: absolute;
  top: calc(-1 * var(--borderWidth));
  left: calc(-1 * var(--borderWidth));
  height: calc(100% + var(--borderWidth) * 2);
  width: calc(100% + var(--borderWidth) * 2);
  background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
  border-radius: calc(2 * var(--borderWidth));
  z-index: -1;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}


@keyframes animatedgradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

#create-game h1 {
  margin-bottom: 20px;
}

#create-game h2 {
  margin-bottom: 10px;
  display: inline-block;
  
}

.create-button-group {
  margin-top: 20px;
}

.copy-to-clipboard {
  position: relative;
  cursor: pointer;
  display: inline-block;
}
